import React from "react";
import { string } from "prop-types";
import { Footer } from "antd/lib/layout/layout";
import { Row, Col } from "antd";
import Logo from "../../../images/svg/SpotOnLogo.svg";
import Facebook from "../../../images/svg/fb.svg";
import Instagram from "../../../images/svg/instagram.svg";
import Twitter from "../../../images/svg/twitter.svg";
import Youtube from "../../../images/svg/youtube.svg";
import Linkedin from "../../../images/svg/linkedin.svg";
import iconEA from "../../../images/svg/icons/eA_Icon.svg";
import FooterStyles from "./FooterStyles";

const FooterComponentLP = ({ className }) => {
  return (
    <Footer role="contentinfo" className={`footer ${className}`} lg={24}>
      <Col>
        <Row justify="center" align="middle">
          <Col className="social-section" xl={10} md={12}>
            <div style={{ textAlign: "center" }}>
              <img src={Logo} alt="logo" />
            </div>

            <p className="social-section__text" style={{ textAlign: "center" }}>
              {`© SpotOn Transact, LLC. ${new Date().getFullYear()}. All Rights Reserved.`}
            </p>
            <p
              className="social-section__text"
              style={{ textAlign: "center", fontSize: 10 }}
            >
              {`SpotOn Transact, LLC. is a Registered Partner/ISO of Merrick Bank, 
              South Jordan, UT & Wells Fargo Bank, N.A., Concord, CA & Elavon Inc., 
              Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN].`}
            </p>
            <ul
              className="social-section__links"
              style={{ justifyContent: "center", marginBottom: 20 }}
            >
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/spoton"
                >
                  <img src={Facebook} alt="facebook-social-icon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/spoton/"
                >
                  <img src={Instagram} alt="instagram-social-icon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/spoton"
                >
                  <img src={Twitter} alt="instagram-social-icon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://youtube.com/c/spotoninc"
                >
                  <img src={Youtube} alt="youtube-social-icon" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/spoton/mycompany/"
                >
                  <img src={Linkedin} alt="linkedin-social-icon" />
                </a>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                position: "relative",
                marginBottom: 40,
              }}
            >
              <a
                target="blank"
                rel="noopener"
                href="https://www.essentialaccessibility.com/spoton?utm_source=spotonhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=spoton"
              >
                <img
                  style={{ width: 125, marginTop: 15, magrin: "auto" }}
                  src={iconEA}
                  alt="This icon serves as a link to download the eSSENTIAL Accessibility assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion."
                />
              </a>
            </div>
          </Col>
        </Row>
      </Col>
      <FooterStyles />
    </Footer>
  );
};

FooterComponentLP.propTypes = {
  className: string,
};

FooterComponentLP.defaultProps = {
  className: "",
};

export default FooterComponentLP;
