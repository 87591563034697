import React from "react";
import { viewports, colors } from "../../../style-vars";
import helpCenterBg from "../../../images/footer/help-center-background.png";

const {
  xlOrSmaller,
  xlOrBigger,
  smOrSmaller,
  lgOrSmaller,
  xsOnly,
  mdOrSmaller,
} = viewports;

const FooterStyles = () => (
  <style jsx global>
    {`
      footer.ant-layout-footer.footer {
        position: relative;
        width: 100%;
        max-width: 1440px;
        padding: 20px;
        background: ${colors.white} !important;
      }

      footer.ant-layout-footer .footer__top {
        border-bottom: solid 2px #e3ecff;
        margin-bottom: 64px;
        padding-bottom: 32px;
      }

      footer.ant-layout-footer .footer__top__title h2 {
        margin: 0;
      }

      footer.ant-layout-footer .footer__top__links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      footer.ant-layout-footer .footer__top__links a {
        margin-right: 25px;
        font-weight: 800;
        font-size: 16px;
      }

      footer.ant-layout-footer .footer__top__links .get-your-demo {
        font-size: 16px;
      }

      footer.ant-layout-footer.footer .social-section__text {
        padding: 15px 20px 25px 0;
        margin: 0;
        color: #748093;
        font-size: 12px;
      }

      footer.ant-layout-footer.footer .social-section__links {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
      }

      footer.ant-layout-footer.footer .social-section__links li {
        width: 50px;
        height: 50px;
        background: #f3f4f6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }
      footer.ant-layout-footer.footer
        .social-section__links
        li:not(:last-of-type) {
        margin-right: 16px;
      }
      footer.ant-layout-footer.footer .social-section__links li a {
        width: 100%;
        height: 100%;
      }
      footer.ant-layout-footer.footer .social-section__links li img {
        width: 100%;
        height: 100%;
        padding: 30%;
      }

      footer.ant-layout-footer .footer__help-center {
        padding: 40px;
        font-size: 16px;
        background: #f4f6f9;
        border-radius: 16px;
        background: url(${helpCenterBg}) no-repeat #f4f6f9;
        background-position-x: 100%;
        background-position-y: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      footer.ant-layout-footer .footer__help-center--mobile {
        min-height: 350px;
      }

      footer.ant-layout-footer .footer__help-center .title {
        line-height: 30px;
        margin-bottom: 0;
      }

      footer.ant-layout-footer .footer__help-center .subtitle,
      footer.ant-layout-footer .footer__help-center .text {
        color: #748093 !important;
      }

      footer.ant-layout-footer .footer__help-center .image-wrapper {
        display: flex;
        justify-content: flex-end;
      }

      footer.ant-layout-footer .footer__help-center .image {
        justify-self: flex-end;
        min-width: 64px;
        height: max-content;
      }

      footer.ant-layout-footer .footer__help-center .contact-us {
        font-size: 18px;
        font-weight: 800;
        color: ${colors.black};
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0 !important;
      }

      footer.ant-layout-footer
        .footer__help-center
        .contact-us.ant-btn.ant-btn-secondary:hover {
        background-color: transparent !important;
        box-shadow: none !important;
      }

      footer.ant-layout-footer.footer .sep {
        width: 100%;
        height: 2px;
        object-fit: contain;
        margin: 50px 0;
      }

      footer.ant-layout-footer.footer .sep img {
        object-fit: unset !important;
      }

      footer.ant-layout-footer.footer .sep.top-sep {
        margin-top: 30px;
      }

      footer.ant-layout-footer.footer .links-container .ant-menu-item:hover {
        background: ${colors.white} !important;
      }

      footer.ant-layout-footer.footer .link-list h4.ant-typography.title {
        font-size: 18px !important;
        font-weight: 700 !important;
        color: #090d12 !important;
      }

      footer.ant-layout-footer .footer__help-center-wrapper {
        margin-top: 54px;
      }

      footer.ant-layout-footer.footer .link-list ul {
        display: flex;
        flex-direction: column;
        border-right: none;
        height: 260px;
        flex-wrap: wrap;
        height: auto;
        //border-bottom: 1px solid #e8f0ff;
        padding-bottom: 48px;
      }
      @media ${viewports.mdOrBigger} {
        footer.ant-layout-footer.footer .link-list ul {
          border-bottom: 1px solid #e8f0ff;
        }
      }
      footer.ant-layout-footer.footer .link-list.products-list ul,
      footer.ant-layout-footer.footer .link-list.company-list ul {
        flex-direction: row;
      }
      footer.ant-layout-footer.footer .link-list.company-list {
        margin-top: 48px;
      }
      footer.ant-layout-footer.footer .link-list.company-list ul {
        border-bottom: none;
        padding-bottom: 0;
      }

      footer.ant-layout-footer.footer .link-list li {
        padding: 0;
        font-size: 16px;
      }
      footer.ant-layout-footer.footer .link-list.products-list li,
      footer.ant-layout-footer.footer .link-list.company-list li {
        flex: 0 1 33%;
      }

      footer.ant-layout-footer.footer .link-list li a {
        color: #748093 !important;
      }

      footer.ant-layout-footer.footer .link-list li.ant-menu-item-selected,
      footer.ant-layout-footer.footer .link-list li:active {
        background: transparent;
      }
      footer.ant-layout-footer.footer .link-list li:hover a {
        color: ${colors.primary} !important;
      }

      footer.ant-layout-footer.footer .products-list .ant-menu-item:hover {
        background: ${colors.white} !important;
      }

      footer.ant-layout-footer.footer .restaurant-list ul {
        height: auto;
      }

      footer.ant-layout-footer.footer .company-list ul {
        height: 120px;
      }

      footer.ant-layout-footer .footer__bottom {
        margin-top: 62px;
        padding-bottom: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e8f0ff;
        padding-top: 30px;
      }

      footer.ant-layout-footer .footer__bottom__sep {
        width: 100%;
        height: 2px;
        object-fit: contain;
        margin-bottom: 30px;
      }

      footer.ant-layout-footer .footer__bottom ul {
        display: flex;
        border-right: none;
      }

      footer.ant-layout-footer .footer__bottom ul li a {
        color: #748093 !important;
      }

      footer.ant-layout-footer .footer__bottom ul li.ant-menu-item-selected,
      footer.ant-layout-footer .footer__bottom ul li:active {
        background: transparent !important;
      }
      footer.ant-layout-footer .footer__bottom ul li:hover a {
        color: ${colors.primary} !important;
      }

      footer.ant-layout-footer .footer__bottom__language {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      footer.ant-layout-footer .footer__bottom__language .ant-typography {
        margin-right: 10px;
        font-size: 16px;
        color: #748093 !important;
      }

      footer.ant-layout-footer .login-item {
        width: 100px !important;
        margin-right: 15px;
      }

      footer.ant-layout-footer .login-item .ant-menu-submenu-title {
        color: ${colors.primary} !important;
      }

      footer.ant-layout-footer .login-item .ant-menu-submenu-vertical {
        border-radius: 25px !important;
        overflow: hidden !important;
      }

      footer.ant-layout-footer .login-item .ant-menu-submenu-arrow {
        transform: rotate(90deg);
        color: ${colors.primary} !important;
        margin-top: 3px;
      }

      footer.ant-layout-footer .login-item__pop-up {
        position: fixed;
      }

      @media ${xlOrBigger} {
        footer.ant-layout-footer .footer__help-center--mobile {
          display: none;
        }
      }

      @media ${xlOrSmaller} {
        footer.ant-layout-footer.footer {
          padding: 0 16px;
        }
      }

      @media ${smOrSmaller} {
        footer.ant-layout-footer .footer__top__title {
          text-align: center;
          margin-bottom: 30px;
        }

        footer.ant-layout-footer .footer__top__links {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        footer.ant-layout-footer.footer .social-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        footer.ant-layout-footer .footer__help-center--mobile {
          margin: 50px 0;
        }

        footer.ant-layout-footer.footer .products-list ul {
          max-height: 500px;
        }

        .link-list.company-list ul {
          flex-direction: column !important;
        }

        footer.ant-layout-footer.footer .link-list.company-list {
          margin-top: 0;
        }

        .no-border-bottom ul {
          border-bottom: none !important;
        }

        footer.ant-layout-footer.footer .company-list ul {
          height: auto;
        }

        footer.ant-layout-footer .footer__bottom {
          flex-direction: column-reverse;
          align-items: center;
        }

        footer.ant-layout-footer .footer__bottom__sep {
          margin-bottom: 50px;
          order: 1;
        }

        footer.ant-layout-footer .footer__bottom ul {
          margin-top: 30px;
          flex-direction: column;
          align-items: center;
        }
      }

      @media ${lgOrSmaller} {
        footer.ant-layout-footer .footer__help-center--desktop {
          display: none;
        }

        footer.ant-layout-footer.footer .sep {
          display: none;
        }

        footer.ant-layout-footer.footer .sep.top-sep {
          display: block;
          opacity: 1;
          margin: 50px 0;
        }

        footer.ant-layout-footer.footer .links-container .ant-col {
          height: max-content;
          padding: 10px 0;
        }
      }

      @media ${xsOnly} {
        footer.ant-layout-footer.footer .links-container .ant-col {
          margin-right: 0 !important;
        }
      }

      @media ${viewports.maxContentWidthOrBigger} {
        footer.ant-layout-footer.footer {
          padding: 20px;
        }
      }
      #footer-menu-with-border-right {
        border-right: 1px solid #f0f0f0;
      }
      #footer-menu-without-border-right {
        border-right: 0 !important;
      }
    `}
  </style>
);

export default FooterStyles;
