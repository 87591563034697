/* eslint-disable import/prefer-default-export */
export const getUrlParams = (search) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};
  // eslint-disable-next-line
  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const getHashParams = (hash) => {
  const allHashes = hash.slice(hash.indexOf('#') + 1).split('&');
  const params = {};
  // eslint-disable-next-line
  allHashes.map((item) => {
    const [key, val] = item.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const States = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
