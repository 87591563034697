export const emailValidationRegExp = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const phoneValidationRegExp = new RegExp(
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
);

export const zipValidationRegExp = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);

export const hubSpotFormSubmitBaseUrl =
  "https://api.hsforms.com/submissions/v3/integration/submit/2970821";

export const formItemTypes = {
  INPUT: "input",
  CHECKBOX: "checkbox",
  CHECKBOX_GROUP: "checkbox_group",
  DROPDOWN: "dropdown",
  SEARCHABLE_DROPDOWN: "searchable_dropdown",
  RADIO: "radio",
};

export const formItemVisibilityEventName = "formChange";

export const pagesWithDemoForms = [
  "/restaurant-pos/",
  "/restaurant-pos/pizza-pos/",
  "/fine-dining-pos/",
  "/nightclub-and-bar-pos/",
  "/quick-service-pos/",
  "/casual-dining-pos/",
  "/developer-center/",
  "/food-truck-pos/",
  "/professional-services/",
  "/professional-services/accountants/",
  "/professional-services/plumbers/",
  "/professional-services/veterinarian/",
  "/health-and-beauty/",
  "/health-and-beauty/hair-salons/",
  "/health-and-beauty/nail-salons/",
  "/health-and-beauty/barber/",
  "/health-and-beauty/dentist/",
  "/health-and-beauty/tanning-salon/",
  "/automotive/",
  "/automotive/auto-parts/",
  "/automotive/auto-repair/",
  "/automotive/auto-sales/",
  "/automotive/oil-change-shop/",
  "/automotive/tire-center/",
  "/products/payments/",
  "/products/appointments/",
  "/products/capital/",
  "/products/delivery/",
  "/products/e-commerce/",
  "/products/loyalty/",
  "/products/marketing/",
  "/products/mobile/",
  "/products/online-order-and-delivery/",
  "/products/payroll/",
  "/products/register/",
  "/products/reporting/",
  "/products/review-management/",
  "/products/terminal/",
  "/products/virtual-terminal/",
  "/products/reserve/",
  "/products/sidekick/",
  "/em/sidekick/",
  "/lp/new-york/",
  "/lp/ordering-app/",
  "/lp/online-ordering/",
  "/lp/health-and-beauty/",
  "/lp/retail/",
  "/lp/veterans/",
  "/retail/",
  "/retail/clothing-and-apparel/",
  "/retail/sporting-goods/",
  "/retail/home-decor/",
  "/enterprise/",
  "/enterprise/sports-and-entertainment/",
  "/enterprise/education-campuses/",
  "/enterprise/professional-facilities/",
  "/enterprise/amusements-and-attractions/",
  "/enterprise/enterprise-food-services/",
  "/enterprise/enterprise-retail/",
  "/enterprise/kiosks/",
  "/enterprise/pos-terminals/",
  "/enterprise/handhelds/",
  "/enterprise/payment-devices/",
  "/enterprise/back-of-house-management/",
  "/enterprise/mobile-and-online-ordering/",
  "/enterprise/kitchen-displays/",
];

export const urlIndustries = [
  "restaurants",
  "automotive",
  "health-and-beauty",
  "professional-services",
  "retail",
];

export const resourceCategories = {
  ALL: "all",
  BLOG: "blog",
  WHITE_PAPERS: "white-papers",
  WEBINARS: "webinars",
  EBOOKS: "e-books",
  CASE_STUDIES: "case-studies",
  INFOGRAPHICS: "infographics",
  VIDEOS: "videos",
  TOOLS: "tools",
  DEVELOPER_CENTER: "developer-center",
};

export const faqCategories = {
  POS: "point-of-sale-basics",
  SMALL_BUSINESSES_SOLUTIONS: "small-business-solutions",
  ENTERPRISE: "enterprise-solutions",
  PRODUCTS_AND_SERVICES: "spoton-products-and-services",
};

export const resourceTags = {
  WHITEPAPER: "Whitepaper",
  WEBINAR: "Webinar",
  EBOOK: "Ebook",
  CASE_STUDY: "Case Study",
  VIDEO_TESTIMONIAL: "Video Testimonial",
  TOOL: "Tool",
  DEVELOPER_CENTER: "Developer center",
};

export const resourceIndustries = {
  RESTAURANTS: "Restaurants",
  PROFESSIONAL_SERVICES: "Professional Services",
  RETAIL: "Retail",
  ENTERPRISE: "Enterprise",
  SMALL_BUSINESSES: "Small Businesses",
};

export const resourceGoals = {
  STREAMLINE_OPERATIONS: "Streamline operations",
  GET_SMARTER_DATA: "Get smarter data",
  MANAGE_AND_RETAIN_EMPLOYEES: "Manage & retain employees",
  TAKE_PAYMENTS_EVERYWHERE: "Take payments everywhere",
  REACH_NEW_CUSTOMERS: "Reach new customers",
  CONNECT_AND_SELL_ONLINE: "Connect & sell online",
  BUILD_CUSTOMER_LOYALTY: "Build customer loyalty",
  ENTERPRISE: "Enterprise",
  RESTAURANTS: "Restaurants",
  TRENDS: "Trends",
  RETAIL: "Retail",
};

export const resourceGoalsToGhostSlugsMap = {
  [resourceGoals.STREAMLINE_OPERATIONS]: "streamline-operations",
  [resourceGoals.GET_SMARTER_DATA]: "get-smarter-data",
  [resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES]: "manage-retain-employees",
  [resourceGoals.TAKE_PAYMENTS_EVERYWHERE]: "take-payments-everywhere",
  [resourceGoals.REACH_NEW_CUSTOMERS]: "reach-new-customers",
  [resourceGoals.CONNECT_AND_SELL_ONLINE]: "connect-sell-online",
  [resourceGoals.BUILD_CUSTOMER_LOYALTY]: "build-customer-loyalty",
  [resourceGoals.ENTERPRISE]: "enterprise",
  [resourceGoals.RESTAURANTS]: "restaurants",
  [resourceGoals.TRENDS]: "trends",
  [resourceGoals.RETAIL]: "retail",
};

export const spotonProducts = {
  ASSIST: "SpotOn Assist",
  CAPITAL: "SpotOn Capital",
  RESTAURANT: "SpotOn Restaurant",
  SERVE: "SpotOn Serve",
  ORDER: "SpotOn Order",
  QR: "SpotOn QR",
  RESERVE: "SpotOn Reserve",
  WEBSITE: "SpotOn Website",
  PAYMENTS: "SpotOn Payments",
  PAYROLL: "Payroll",
  DASHBOARD: "SpotOn Dashboard",
  SIDEKICK: "SpotOn Sidekick",
  LOYALTY: "SpotOn Loyalty",
  RETAIL: "SpotOn Retail",
  GIFT_CARDS: "Gift Cards",
  POS: "SpotOn POS",
  ENTERPRISE_POS: "Enterprise POS",
  SELF_SERVICE_KIOSKS: "Self-service Kiosks",
  E_COMMERCE: "SpotOn Ecommerce",
  ONLINE_ORDERING: "Mobile & online ordering",
  HANDHELDS: "Handhelds",
  KITCHEN_DISPLAYS: "Kitchen Displays",
  VENDING_INVENTORY: "Vending & inventory",
  LABOR_MANAGEMENT: "SpotOn Teamwork",
  APPOINTMENTS: "SpotOn Appointments",
  MARGIN_EDGE: "SpotOn MarginEdge Integration",
  GUSTO_PAYROLL_INTEGRATION: "Gusto Payroll Integration",
};

export const productToUrlMap = {
  [spotonProducts.ASSIST]: "/lp/assist/",
  [spotonProducts.CAPITAL]: "/products/capital/",
  [spotonProducts.RESTAURANT]: "/restaurant-pos/",
  [spotonProducts.SERVE]: "/restaurant-pos/",
  [spotonProducts.ORDER]: "/products/online-order-and-delivery/",
  [spotonProducts.QR]: "/products/online-order-and-delivery/",
  [spotonProducts.RESERVE]: "/products/reserve/",
  [spotonProducts.WEBSITE]: "/products/website/",
  [spotonProducts.PAYMENTS]: "/products/virtual-terminal/",
  [spotonProducts.PAYROLL]: "/products/payroll/",
  [spotonProducts.DASHBOARD]: "/products/payments/",
  [spotonProducts.APPOINTMENTS]: "/products/appointments/",
  [spotonProducts.SIDEKICK]: "/products/sidekick/",
  [spotonProducts.LOYALTY]: "/products/loyalty/",
  [spotonProducts.RETAIL]: "/retail/",
  [spotonProducts.GIFT_CARDS]: "#",
  [spotonProducts.POS]: "/enterprise/",
  [spotonProducts.ENTERPRISE_POS]: "/enterprise/pos-terminals/",
  [spotonProducts.SELF_SERVICE_KIOSKS]: "/enterprise/kiosks/",
  [spotonProducts.E_COMMERCE]: "/products/e-commerce/",
  [spotonProducts.ONLINE_ORDERING]: "/enterprise/mobile-and-online-ordering/",
  [spotonProducts.HANDHELDS]: "/enterprise/handhelds/",
  [spotonProducts.KITCHEN_DISPLAYS]: "/enterprise/kitchen-displays/",
  [spotonProducts.VENDING_INVENTORY]: "/enterprise/back-of-house-management/",
  [spotonProducts.LABOR_MANAGEMENT]: "/products/labor-management/",
  [spotonProducts.MARGIN_EDGE]: "https://www.marginedge.com/lp/spoton",
  [spotonProducts.GUSTO_PAYROLL_INTEGRATION]: "#",
};

export const lineOfBusiness = {
  VENUES: "Venues",
  HOSPITALITY: "Hospitality",
  RETAIL_AND_SERVICES: "Retail & Services",
};
