import React from "react";
import { Link } from "gatsby";
import { string, func, bool, oneOf } from "prop-types";
import chevron from "../../../images/svg/chevron_left.svg";

const CtaPrimary = ({
  className,
  title,
  onClick,
  target,
  large,
  disabled,
  newTab,
}) => {
  const Chevron = <img className="ml-2.5" alt="chevron icon" src={chevron} />;
  const styles = `
        cta-p
        flex flex-row items-center
        px-6 py-2.5
        rounded-full border-none cursor-pointer
        text-white font-bold bg-primary hover:bg-opacity-70
        ${large ? "text-cta-large" : "text-cta"}
        transition-all duration-200
        ${disabled ? "opacity-75" : ""}
        ${className} 
      `;

  if (target) {
    if (newTab) {
      return (
        <a
          className={`${styles} hover:text-white w-max`}
          href={target}
          target="_blank"
          rel="noreferrer"
        >
          <span className="pb-[3px]">{title}</span>
          {Chevron}
        </a>
      );
    }

    return (
      <Link className={`${styles} hover:text-white w-max`} to={target}>
        <span className="pb-[3px]">{title}</span>
        {Chevron}
      </Link>
    );
  }
  return (
    <button
      className={styles}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <span className="pb-[3px]">{title}</span>
      {Chevron}
    </button>
  );
};

CtaPrimary.propTypes = {
  className: string,
  title: string.isRequired,
  onClick: func,
  target: string,
  large: bool,
  disabled: bool,
  newTab: bool,
};

CtaPrimary.defaultProps = {
  className: "",
  onClick: () => true,
  target: null,
  large: false,
  disabled: false,
  newTab: false,
};

export default CtaPrimary;
