/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description,
  lang,
  title,
  image,
  robotsData,
  cannonical,
  productSchemaSR,
  orgSchema,
  customSchema,
  noIndex,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title || "";
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      {/* <link rel="stylesheet" href="https://use.typekit.net/yps7tid.css" async /> */}
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={metaDescription} />
      <meta name="robots" content="noindex, nofollow" />
      {/* {robotsData && <meta name="googlebot" content={robotsData} />} */}
      {/* {noIndex && <meta name="robots" content="noindex, nofollow" />} */}

      <meta
        name="facebook-domain-verification"
        content="fqr2cgbq893lt2kuvppbkwh1h9zldj"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <script type="text/javascript">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("852861719"); `}
      </script>

      {/* <script src="https://www.googleoptimize.com/optimize.js?id=OPT-M6NXG5Z" /> */}
      {/* {documentTrue && document.readyState === "complete" && ( */}
      {/*  <script */}
      {/*    id="ze-snippet" */}
      {/*    src="https://static.zdassets.com/ekr/snippet.js?key=812fbdeb-b029-4788-9e84-55520a1b73e5" */}
      {/*  /> */}
      {/* )} */}

      {/* {loadChat && (
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=812fbdeb-b029-4788-9e84-55520a1b73e5"
        />
      )} */}
      <link rel="stylesheet" href="https://use.typekit.net/fgj5amk.css" />

      <link rel="stylesheet" href="https://use.typekit.net/fgj5amk.css" />

      {cannonical.length > 0 && <link rel="canonical" href={cannonical} />}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Spoton Transact LLC",
          "image": "${image}",
          "telephone": "(877) 814-4102",
          "logo": "https://www.spoton.com/SpotOn-Logo.jpg", 
          "url": "https://www.spoton.com/",
          "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 09:00 AM-06:00 PM",
          "description": "SpotOn was built to help businesses compete and win with intuitive software, point-of-sale, and payment solutions.",
          "priceRange": "$$",
          "sameAs": [
            "https://www.google.com/maps/place/Spoton+Inc/@41.6644608,-88.2017548,11.76z/data=!4m5!3m4!1s0x880e2cc638be0111:0x709ec5fc819a101f!8m2!3d41.8820532!4d-87.6404348?shorturl=1",
            "https://www.facebook.com/spoton/",
            "https://twitter.com/spoton",
            "https://www.linkedin.com/company/spoton/",
            "https://www.youtube.com/c/spotoninc",
            "https://www.instagram.com/spoton",
            "https://www.capterra.com/p/197473/SpotOn-Restaurant/"
          ],
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "100 California Street, 9th Floor",
            "addressLocality": "San Francisco",
            "addressRegion": "CA",
            "postalCode": "94111",
            "addressCountry": "US"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": 4.7,
            "reviewCount": 117
          }
        }`}
      </script>
      {customSchema}
      {productSchemaSR && (
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "Product",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.7",
                  "reviewCount": "117"
                },
                "description": "Online ordering, digital reservations & waitlisting, and elite reporting to keep your guests and employees happy—it’s all possible with SpotOn restaurant POS.",
                "name": "SpotOn restaurant point of sale",
                "image": "${image}",
                "offers": {
                  "@type": "Offer",
                  "availability": "https://schema.org/InStock",
                  "price": "65.00",
                  "priceCurrency": "USD"
                },
                "review": [
                  {
                    "@type": "Review",
                    "author": "Claude l.",
                    "datePublished": "2021-15-09",
                    "reviewBody": "From the rep, to the customer service and product. We are happy with the results",
                    "name": " Perfect for my growing Resturaunt business",
                    "reviewRating": {
                      "@type": "Rating",
                      "bestRating": "5",
                      "ratingValue": "5",
                      "worstRating": "1"
                    }
                  },
                  {
                    "@type": "Review",
                    "author": "Hillary H.",
                    "datePublished": "2021-15-09",
                    "reviewBody": "When looking for a new POS, we were looking for a unicorn product that could do things we only dreamed of, and found it in SpotOn. We can integrate Loyalty, Reserve, POS, Handhelds, Credit Card Processing, Reporting and soon Online Ordering through one program! We wanted handheld devices for speed, order accuracy, and payment table side that did not take away from the guest experience and have loved the clean interface we get with SpotOn too. Our customer service experiences are second to none with extremely fast response times and a service minded team backing what we do everyday.",
                    "name": "Handles Heavy Volume with Ease!",
                    "reviewRating": {
                      "@type": "Rating",
                      "bestRating": "5",
                      "ratingValue": "4",
                      "worstRating": "1"
                    }
                  },
                  {
                    "@type": "Review",
                    "author": "Mark H.",
                    "datePublished": "2021-09-09",
                    "reviewBody": " So far our experience has been very positive. The way the online store integrates to the POS is terrific, allowing us pinpoint control over what we sell, where and when. Adding and 86ing items is a breeze which prevents unhappy customers and the handheld units we use supplement our workflow and make us more efficient. Thew QR codes are also a timely addition to our arsenal and our customers use them for both menu exploration as well as payment and ordering.",
                    "name": "SpotOn for Independent restaurant",
                    "reviewRating": {
                      "@type": "Rating",
                      "bestRating": "5",
                      "ratingValue": "5",
                      "worstRating": "1"
                    }
                  }
                ]
              }`}
        </script>
      )}
      {orgSchema && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "SpotOn",
              "url": "https://www.spoton.com/",
              "logo": "https://www.spoton.com/SpotOn-Logo.jpg",
              "sameAs": [
                "https://www.facebook.com/SpotOn/",
                "https://twitter.com/SpotOn",
                "https://www.youtube.com/channel/UCcDbmZqLtOdLc7ED1N1vvlg"
              ]
            }`}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
  image: null,
  robotsData: `index follow`,
  cannonical: "",
  productSchemaSR: false,
  orgSchema: false,
  noIndex: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.node,
  robotsData: PropTypes.string,
  cannonical: PropTypes.string,
  productSchemaSR: PropTypes.bool,
  orgSchema: PropTypes.bool,
  noIndex: PropTypes.bool,
};

export default SEO;
